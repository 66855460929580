.auth-form form label {
    display: block;
    text-align: left;
    font-size: 80%;
}

.auth-form form input {
    margin-bottom: 15px;
    width: 100%;
    border-radius: 5px;
}

.auth-form form a {
    font-size: 80%;
}

.auth-form form button {
    width: 100%;
    border-radius: 5px;
    background-color: #fad73b;
    color: black;
    font-weight: bold;
    font-size: 100%;
    margin-top: 10px;
}

.auth-form form button:hover {
    background-color: #ffd104;
    color: black;
}

.auth-form {
    padding: 20px;
    box-sizing: border-box;
    background-color: black;
    width: 360px;
}

.login-container {
    margin: 0 auto;
    padding: 16px 16px;
}

p.block {
    padding-top: 14px;
    padding-bottom: 14px;
    border: 2px solid black;
    border-radius: 5px;
    text-align: center;
    font-size: 90%;
}

a {
    color: #fad73b;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.logo {
    margin-bottom: 30px;
    margin-top: 20px;
}

.login-header {
    text-align: center;
    margin-bottom: 50px;
    font-size: 150%;
}