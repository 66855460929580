.Products {
    text-align: center;
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.product-table {
    margin: 1rem;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 0.5rem;
    text-align: left;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
}

th {
    font-size: 85%;
}

th {
    background-color: #751cec;
    font-weight: bold;
    color: white
}

div#filtro-disponibilidad select {
    background-color: #751cec;
    font-weight: bold;
    color: white
}

div#filtro-disponibilidad th {
    border-right: none !important
}

tr:hover {
    background-color: #f5f5f5;
}

tr:hover td {
    background-color: black;
    color: white
}

tr {
    background-color: #fed832;
    color: black
}